<template>
  <div>
    <loader
      v-if="Loadmore"
      object="#9658cc"
      color1="#532a76"
      color2="#d3bae9"
      size="5"
      speed="2"
      bg="#343a40"
      objectbg="#999793"
      opacity="30"
      name="circular"
    />
    <!-- <this-header /> -->
    <div class="container-sm-lot pl-1 pr-1">
      <div class="top-bxt my-1">
        <img
          src="/icon/back.svg"
          alt="back"
          class="cursor-pointer ts-left"
          @click="$router.go(-1)"
        >

        <h3 class="text-primary mb-0">
          พ้อยท์สะสม
        </h3>
      </div>

      <div class="d-flex align-items-center justify-content-center mt-2">
        <div class="ic-point">
          <div class="circle-point">
            <div class="m_point">
              <h3 class="point-now mb-0">
                {{ Commas(mypoint) }}
              </h3>

              <p class="mb-0 -detail">
                point
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <a class="free-point-ten">
          <span class="-title"> ซื้อครบ 10 ชุด รับฟรี 1 ชุด </span>
        </a>
      </div>

      <div class="img-banner my-1">
        <img
          src="@/assets/banner/Banner4.png"
          alt="banner-suvarn"
          class="img-fluid"
        >
      </div>

      <div class="text-center">
        <u class="text-primary"> การได้รับพ้อยท์(Point) </u>
        <p class="mb-0 small text-primary">
          ทุกการสั่งซื้อสลากออนไลน์ จะได้รับแต้มคะแนน (Point)
        </p>
        <p class="mb-0 small text-primary">
          ทุสามารถนำไปลุ้นรางวัลหรือแลกซื้อเพิ่มได้ทันที
        </p>
      </div>

      <div class="bx-detail my-1">
        <table class="w-100 my-font">
          <tbody style="font-size: 1.14rem">
            <tr
              v-for="(item, index) in pointList"
              :key="index"
            >
              <td style="width: 30%">
                <i class="fas fa-gift" /> ซื้อล็อตเตอรี่
              </td>
              <td style="width: 32%">
                <div class="d-flex">
                  <div class="w-25 text-right">
                    {{ item.set }}
                  </div>

                  <div class="w-75 ml-25">
                    ชุด
                    <small v-if="item.sale">(ลด{{ item.sale }}%)</small>
                  </div>
                </div>
              </td>
              <td style="width: 23%">
                ได้รับพ้อยท์
              </td>
              <td style="width: 15%">
                <div class="d-flex">
                  <div class="w-50 text-right">
                    {{ item.point }}
                  </div>

                  <div class="w-75 text-right">
                    point
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row my-1">
        <div class="col-md-6 col-6 p-25">
          <img
            src="/newIcon/u_point_box.png"
            alt="point"
            class="img-fluid cursor-pointer"
            @click="$router.push({ path: '/lucky-box?type=usepoint' })"
          >
        </div>

        <div class="col-md-6 col-6 p-25">
          <img
            src="/newIcon/u_point_spin.png"
            alt="p-wheel"
            class="img-fluid cursor-pointer"
            @click="
              $router.push({
                name: 'lucky-wheel',
                params: { point: 'freePoint' },
              })
            "
          >
        </div>
      </div>

      <!-- <div class="text-center">
        <u class=" text-primary mb-0">
          ประวัติการทำรายการ
        </u>
      </div> -->
    </div>
    <ThisFooter />
    <!-- </div> -->
  </div>
</template>

<script>
import ThisFooter from './components/ThisFooter.vue'

export default {
  name: 'Home',
  components: {
    // ThisHeader,
    ThisFooter,
  },
  data() {
    return {
      mypoint: 0,
      Loadmore: false,
      pointList: [
        { set: 1, point: 10, sale: null },
        { set: 2, point: 25, sale: 5 },
        { set: 5, point: 70, sale: 10 },
        { set: 10, point: 160, sale: 20 },
      ],
      items: [],
      userData: JSON.parse(localStorage.getItem('userData')), // getlocalstorage
    }
  },
  created() {
    if (!this.userData) {
      this.$router.push({ name: 'home' })
    }
  },
  mounted() {
    this.getBalance()
  },
  methods: {
    getBalance() {
      this.Loadmore = true
      this.$http.get('balance/getbalance').then(ress => {
        // console.log(ress.data)
        this.mypoint = ress.data.point
        localStorage.setItem('balance', JSON.stringify(ress.data.balance))
        this.Loadmore = false
      })
    },
    Commas(x) {
      if (!x) {
        return 0
      }
      if (x % 1 !== 0) {
        return Number(x)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    sleep(time) {
      return new Promise(resolve => setTimeout(resolve, time))
    },
  },
}
</script>

  <style scoped></style>
